// First we select the element we want to target

document.addEventListener("DOMContentLoaded", function () {
  const target = document.querySelector(".yetu-numbers");

  let options = {
    rootMargin: "100px",
    threshold: 1.0,
  };
  // Next we want to create a function that will be called when that element is intersected

  function handleIntersection(entries) {
    if (
      document.getElementsByClassName("yetu-numbers__items--item").length >= 1
    ) {
      // The callback will return an array of entries, even if you are only observing a single item
      entries.map((entry) => {
        if (entry.isIntersecting) {
          // Selector Companies
          const counter = document.querySelectorAll(
            ".yetu-numbers .yetu-numbers__items--item__number--integer"
          );

          for (let n of counter) {
            const updateCount = () => {
              const target = +n.getAttribute("data-target");
              const count = +n.innerText;
              const speed = +n.getAttribute("data-speed"); // change animation speed here
              const inc = target / speed;
              if (count < target) {
                n.innerText = Math.ceil(count + inc);
                setTimeout(updateCount, speed);
              } else {
                n.innerText = target;
              }
            };

            updateCount();
          }
          // Main function
        }
      });
    }
  }

  // Next we instantiate the observer with the function we created above. This takes an optional configuration
  // object that we will use in the other examples.
  if (target) {
    const observer = new IntersectionObserver(handleIntersection, options);

    // Finally start observing the target element
    observer.observe(target);
  }
});
