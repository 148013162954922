// // Function to add the custom attribute with the specified value to the element
// function addCustomAttribute(element, attribute, value) {
//   element.setAttribute(attribute, value);
// }

// // // Get the main container element
// // const mainContainer = document.getElementById("main");

// // // Get all heading elements (h1 to h6) inside the main container
// // const headingElements = mainContainer.querySelectorAll(
// //   ".hd-content__paragraph, .hd-content__btn, .hd-content__form"
// // );

// // // if (mainContainer && headingElements) {
// // //   // Loop through each heading element and add the custom attribute "data-tor"
// // //   headingElements.forEach((heading, index) => {
// // //     // const value = "h1,h2,h3,h4,h5,h6, p, li => inview:[fade.in pull.up(sm)] slow delay(/0+150ms/);";
// // //     const value =
// // //       addCustomAttribute(heading, "data-tor-group", value);
// // //   });
// // // }
