/* -------------------------------------------------------------------------- */
/*                                  Scroller                                  */
/* -------------------------------------------------------------------------- */
document.addEventListener("DOMContentLoaded", function () {
  const navbar = document.querySelector(".site-header__nav")!;

  window.addEventListener("scroll", () => {
    // Check the scroll position
    if (window.scrollY > 0) {
      // If it's greater than 0, add the 'scrolled' class to the navbar
      navbar.classList.add("scrolled");
    } else {
      // If it's 0 or less, remove the 'scrolled' class from the navbar
      navbar.classList.remove("scrolled");
    }
  });
});
