document.addEventListener("DOMContentLoaded", function () {
  // Check if the class ".cky-hide" exists in the document
  const elementsWithClass = document.querySelectorAll('.cky-consent-container.cky-hide');
  
  if (elementsWithClass.length > 0) {
    // console.log('The class ".cky-hide" exists in the document.');
    
    // Add the class "animation-disabled" to each element with the "cky-hide" class
    elementsWithClass.forEach(element => {
      element.classList.add('animation-disabled');
    });
    
    // console.log('Added class "animation-disabled" to elements with class "cky-consent-container.cky-hide".');
  } else {
    // console.log('The class "cky-consent-container.cky-hide" does not exist in the document.');
  }
});
