// /* -------------------------------------------------------------------------- */
// /*                        Adds class if icon is clicked                       */
// /* -------------------------------------------------------------------------- */

// document.addEventListener('DOMContentLoaded', () => {

//   var searchWrap = document.getElementById("search-mobile-function");

//     document.getElementById("search-mobile-link").addEventListener("click", function(event){
//       event.preventDefault();
//     });

//     document.getElementById('search-mobile-link').addEventListener("click", function () {
//         searchWrap.classList.add("search-mobile-link-active");
//         document.getElementById("searchInputMob").focus();
//     });

//   document.getElementById('close-search').addEventListener("click", function () {
//         event.preventDefault();
//         searchWrap.classList.remove("search-mobile-link-active");
//     });

// });
