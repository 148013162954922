import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementsByClassName("splide-market").length >= 1) {
    const splide = new Splide(".splide-market", {
      type: "loop",
      drag: "free",
      focus: "center",
      autoWidth: true,
      pagination: false,
      autoScroll: {
        speed: 0.5,
      },
    });

    splide.mount({ AutoScroll });
  }

  if (document.getElementsByClassName("splide-esios").length >= 1) {
    const esios = new Splide(".splide-esios", {
      type: "loop",
      focus: "center",
      perPage: 1,
      pagination: false,
      arrows: false,
      width: "230px",
      autoplay: true,
      interval: 3000,
      speed: 800,
      autoScroll: {
        speed: 0.5,
      },
    });

    esios.on("move", (slide) => {
      // Remove the custom class from all slides
      const img = document.querySelector(".yetu-esios__wrap--left img");

      img?.classList.add("img-anim");

      setTimeout(function () {
        img?.classList.remove("img-anim");
      }, 1000);
    });

    esios.mount();
  }

  if (document.getElementsByClassName("splide-testimonial").length >= 1) {
    const splideProof = new Splide(".splide-testimonial", {
      focus: "center",
      type: "fade",
      speed: 1000,
      autoWidth: true,
      pagination: false,
      rewind: true,
      perPage: 1,
      classes: {
        active: "active",
      },
    });

    splideProof.on("active", (slide) => {
      // Remove the custom class from all slides
      const slides = document.querySelectorAll(".splide__slide");
      slides.forEach((slide) => {
        slide.classList.remove("active");
      });

      // Add the custom class to the active slide
      slide.slide.classList.add("active");
    });

    splideProof.mount();
  }
});
